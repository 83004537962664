import { ContentAreaField } from '@hultafors/snickers/types';

export function getTitleFromContentArea(content?: ContentAreaField[]): string {
  if (!content?.length) {
    return '';
  }
  const found = content.find((item) => item?.__typename?.toLowerCase().includes('hero') && ('title' in item) && item.title);
  if (!found || !('title' in found) || !found.title) {
    return '';
  }
  return found.title;
}
