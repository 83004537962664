import { MetaData } from '@hultafors/shared/helpers';
import { FileField } from '@hultafors/shared/types';

import { SeoField } from '@hultafors/snickers/types';

/**
 * @desc Generates OG tags object from Dato SEO object.
 * @param object Dato SEO object
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMeta = (seo: SeoField, lang = 'en'): MetaData => {
  if (!seo) {
    return {
      description: '',
      lang,
      title: '',
    };
  }
  const tags: MetaData = {
    description: seo.description ? seo.description : '',
    lang,
    title: seo.title ? seo.title : '',
  };

  if (seo.image) {
    tags.image = seo.image as unknown as FileField;
  }
  return tags;
};

/**
 * @desc Generates OG tags object
 * @param string title
 * @param string description
 * @param string image url
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMetaManually = ({
  title,
  description,
  imageUrl,
  lang = 'en',
}: {
  title: string;
  description: string;
  imageUrl: string;
  lang: string;
}): MetaData => {
  const tags: MetaData = {
    description: description ? description : '',
    lang,
    title: title ? title : '',
  };

  if (imageUrl) {
    tags.image = {
      url: imageUrl,
      // width: 310,
      // height: 310
    };
  }

  return tags;
};
